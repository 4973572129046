export const siteSettings = {
  name: 'Banana',
  description:
    'Технологи болон Гоо сайхны төвлөрсөн дэлхийн брэндүүдийн нэгдсэн онлайн худалдаа...',
  author: {
    name: 'Digital Mall',
    websiteUrl: 'https://bananamall.mn',
    address: '',
  },
  logo: {
    url: '/fb_cover.png',
    alt: 'BananaMall.mn',
    href: '/',
    width: 1200,
    height: 630,
  },
}
