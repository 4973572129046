import { useMemo, useContext, useReducer, createContext } from 'react'

const initialState = {
  mobileUserProfile: false,
  displayFilter: false,
  displayModal: false,
  displayCart: false,
  displaySearch: false,
  modalView: 'LOGIN_VIEW',
  drawerView: null,
  modalData: null,
  toastText: '',
}

export const UIContext = createContext(initialState)

UIContext.displayName = 'UIContext'

function uiReducer(state, action) {
  switch (action.type) {
    case 'OPEN_USER_PROFILE': {
      return {
        ...state,
        mobileUserProfile: true,
      }
    }
    case 'CLOSE_USER_PROFILE': {
      return {
        ...state,
        mobileUserProfile: false,
      }
    }
    case 'OPEN_CART': {
      return {
        ...state,
        displayCart: true,
      }
    }
    case 'CLOSE_CART': {
      return {
        ...state,
        displayCart: false,
      }
    }
    case 'OPEN_SEARCH': {
      return {
        ...state,
        displaySearch: true,
      }
    }
    case 'CLOSE_SEARCH': {
      return {
        ...state,
        displaySearch: false,
      }
    }
    case 'OPEN_FILTER': {
      return {
        ...state,
        displayFilter: true,
      }
    }
    case 'CLOSE_FILTER': {
      return {
        ...state,
        displayFilter: false,
      }
    }
    case 'OPEN_MODAL': {
      return {
        ...state,
        displayModal: true,
        mobileUserProfile: false,
      }
    }
    case 'CLOSE_MODAL': {
      return {
        ...state,
        displayModal: false,
      }
    }
    case 'SET_MODAL_VIEW': {
      return {
        ...state,
        modalView: action.view,
      }
    }
    case 'SET_DRAWER_VIEW': {
      return {
        ...state,
        drawerView: action.view,
      }
    }
    case 'SET_MODAL_DATA': {
      return {
        ...state,
        modalData: action.data,
      }
    }
    case 'SET_TOAST_TEXT': {
      return {
        ...state,
        toastText: action.text,
      }
    }
    case 'SET_USER_AVATAR': {
      return {
        ...state,
        userAvatar: action.value,
      }
    }
  }
}

export const UIProvider = (props) => {
  const [state, dispatch] = useReducer(uiReducer, initialState)

  const openUserProfile = () => dispatch({ type: 'OPEN_USER_PROFILE' })
  const closeUserProfile = () => dispatch({ type: 'CLOSE_USER_PROFILE' })
  const toggleUserProfile = () =>
    state.mobileUserProfile
      ? dispatch({ type: 'OPEN_USER_PROFILE' })
      : dispatch({ type: 'CLOSE_USER_PROFILE' })
  const openCart = () => dispatch({ type: 'OPEN_CART' })
  const closeCart = () => dispatch({ type: 'CLOSE_CART' })
  const openFilter = () => dispatch({ type: 'OPEN_FILTER' })
  const closeFilter = () => dispatch({ type: 'CLOSE_FILTER' })

  const openModal = () => dispatch({ type: 'OPEN_MODAL' })
  const closeModal = () => dispatch({ type: 'CLOSE_MODAL' })
  const openSearch = () => dispatch({ type: 'OPEN_SEARCH' })
  const closeSearch = () => dispatch({ type: 'CLOSE_SEARCH' })

  const setUserAvatar = (_value) =>
    dispatch({ type: 'SET_USER_AVATAR', value: _value })

  const setModalView = (view) => dispatch({ type: 'SET_MODAL_VIEW', view })
  const setDrawerView = (view) => dispatch({ type: 'SET_DRAWER_VIEW', view })
  const setModalData = (data) => dispatch({ type: 'SET_MODAL_DATA', data })

  const value = useMemo(
    () => ({
      ...state,
      openUserProfile,
      closeUserProfile,
      toggleUserProfile,
      openCart,
      closeCart,
      openFilter,
      closeFilter,
      openModal,
      closeModal,
      openSearch,
      closeSearch,
      setModalView,
      setDrawerView,
      setUserAvatar,
      setModalData,
    }),
    [state]
  )

  return <UIContext.Provider value={value} {...props} />
}

export const useUI = () => {
  const context = useContext(UIContext)
  if (context === undefined) {
    throw new Error(`useUI must be used within a UIProvider`)
  }
  return context
}

export const ManagedUIContext = ({ children }) => (
  <UIProvider>{children}</UIProvider>
)
