import useSWR from 'swr'
import { fetcher, hydraFetcher } from '@/actions/index'

export const useGetCategoryRoot = ({ subUrl, initData }) => {
  const { data, error, ...rest } = useSWR(
    [process.env.BASE_URL + subUrl],
    hydraFetcher,
    { initialData: initData, refreshInterval: 30000 }
  )
  return { data, error, loading: !data && !error, ...rest }
}

export const useGetCategoryChild = ({ subUrl }) => {
  const { data, error, ...rest } = useSWR(
    [process.env.BASE_URL + subUrl],
    fetcher
  )
  return { data, error, loading: !data && !error, ...rest }
}

export const useGetFeaturedCategories = ({ subUrl }) => {
  const { data, error, ...rest } = useSWR(
    [process.env.BASE_URL + subUrl],
    fetcher
  )
  return { data, error, loading: !data && !error, ...rest }
}
