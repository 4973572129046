import { useEffect, useMemo, useRef, useState } from 'react'
import { useRouter } from 'next/router'
import Image from 'next/image'
import { useLocalStorage } from '@/utils/use-local-storage'
import isEmpty from '@/utils/isEmpty'
import { useUpdate } from 'react-use'
import { isBrowser, isMobile } from 'react-device-detect'

function useMounted() {
  const mounted = useRef(false)
  const update = useUpdate()
  useEffect(() => {
    if (mounted.current == false) {
      mounted.current = true
      update()
    }
  }, [update])
  return mounted.current
}

function SaveDataToLocalStorage(data) {
  let a = []
  // Parse the serialized data back into an aray of objects
  a = JSON.parse(localStorage.getItem('searchValue')) || []
  // Push the new data (whether it be an object or anything else) onto the array
  a.push(data)
  // Re-serialize the array back into a string and store it in localStorage
  localStorage.setItem('searchValue', JSON.stringify(a))
}

const SearchBar = ({ mobile }) => {
  const mounted = useMounted()
  const router = useRouter()
  const [value] = useLocalStorage('searchValue', [])
  const [searchValue, setSearchValue] = useState(
    router.query.q ? router.query.q : ''
  )
  const [searchList, setSearchList] = useState(value || [])
  const [searchPop, setSearchPop] = useState(false)

  useEffect(() => {
    router.prefetch('/search')
  }, [])

  useEffect(() => {
    if (!router.query.q) {
      setSearchValue('')
    }
  }, [router.query.q])

  function inputSearch(e) {
    const value = e.currentTarget.value
    setSearchValue(value)
  }
  function onClickSearch() {
    setSearchPop(!searchPop)
  }
  function _onBlur() {
    if (searchPop) {
      setSearchPop(false)
    }
  }

  const addSearchValue = (e, v) => {
    e.preventDefault()
    setSearchPop(false)
    setSearchValue(v)
    router.push(
      {
        pathname: `/search`,
        query: v ? { q: v } : {},
      },
      undefined,
      { shallow: true }
    )
  }
  function searchButton() {
    setSearchPop(false)
    router.push(
      {
        pathname: `/search`,
        query: searchValue ? { q: searchValue } : {},
      },
      undefined,
      { shallow: true }
    )
    let result = searchList.filter((t) => t === searchValue)
    if (!isEmpty(searchValue) && isEmpty(result) === true) {
      SaveDataToLocalStorage(searchValue)
      setSearchList((prevArray) => [...prevArray, searchValue])
    }
  }

  const searchComplete = (e) => {
    {
      e.preventDefault()
      if (e.key === 'Enter') {
        const q = e.currentTarget.value
        setSearchPop(false)
        let result = searchList.filter((t) => t === q)
        if (!isEmpty(q) && isEmpty(result) === true) {
          SaveDataToLocalStorage(q)
          setSearchList((prevArray) => [...prevArray, q])
        }

        router.push(
          {
            pathname: `/search`,
            query: q ? { q } : {},
          },
          undefined,
          { shallow: true }
        )
      }
    }
  }
  function _onFonus() {
    if (!searchPop) {
      setSearchPop(true)
    }
  }

  return (
    <>
      <div
        className={`${
          !mobile && 'd-none'
        } top-search d-lg-flex align-items-center flex-grow-1`}
      >
        <input
          className={`form-control ${searchPop ? 'active' : ''}`}
          type="text"
          placeholder="Та юу хайж байна вэ?"
          aria-label="Search"
          value={searchValue}
          onKeyUp={(e) => searchComplete(e)}
          onClick={onClickSearch}
          autoComplete="off"
          onChange={(event) => inputSearch(event)}
          //onMouseUp={onClickSearch}
          // onFocus={_onFonus}
          // onfocusout={unFocus}
        />
        {mounted && searchPop && searchList.length > 0 && isBrowser && (
          <>
            <div className="search-drop">
              <p>Өмнөх хайлтууд</p>
              <ul className="list-unstyled">
                {searchList
                  .sort((a, b) => (a < b ? 1 : -1))
                  .slice(0, 10)
                  .map((v, i) => {
                    return (
                      <li key={i} onClick={(e) => addSearchValue(e, v)}>
                        <button
                          type={'button'}
                          onClick={(e) => addSearchValue(e, v)}
                        >
                          {v}
                        </button>
                      </li>
                    )
                  })}
              </ul>
            </div>
          </>
        )}

        <button type="button" className="btn">
          <Image
            src="/static/images/icons/search.svg"
            alt="Banana logo"
            layout={'fixed'}
            width={24}
            height={24}
            quality={75}
            onClick={searchButton}
          />
        </button>
      </div>
    </>
  )
}
export default SearchBar
