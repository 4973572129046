import { Fragment, useState } from 'react'
import Link from 'next/link'
import {
  useGetCategoryRoot,
  useGetFeaturedCategories,
} from '@/actions/categories'
import HeadFeaturedCategroyLoading from '@/components/loading/headFeaturedCategoryLoading'
import Image from 'next/image'
import { useRouter } from 'next/router'
import { useData } from '@/contexts/dataContext'
const ChildItem = ({ children, handleClick }) => {
  return (
    <div className="list-wrap">
      <Link
        href={{
          pathname: `/category/${children?.translations?.mn_MN?.slug}`,
        }}
      >
        <a className="title" onClick={(e) => handleClick(e, children)}>
          {children?.translations?.mn_MN?.name}
        </a>
      </Link>
    </div>
  )
}

const Item = ({
  children,
  id,
  techHovered,
  setTechHovered,
  beautyHovered,
  setBeautyHovered,
  mebelHovered,
  setMebelHovered,
  outletHovered,
  setOutletHovered,
  i,
  handleClick,
}) => {
  const childItems =
    children &&
    children?.children.map((value, key) => {
      return (
        <ChildItem key={key} handleClick={handleClick}>
          {value}
        </ChildItem>
      )
    })
    const classActive = () => {
      switch (i) {
        case 0:
          techHovered
          break;
        case 1:
          beautyHovered
          break;
        case 2:
          mebelHovered
          break;
        case 3:
          outletHovered
          break;
        default:
          break;
      }
      
    }
    const onMouseOver = () => {
      switch (i) {
        case 0:
          setTechHovered(id)
            break;
          case 1:
            setBeautyHovered(id)
            break;
          case 2:
            setMebelHovered(id)
            break;
          case 3:
            setOutletHovered(id)
            break;
          default:
            break;
      }
    }
    const onMouseOut = () => {
      switch (i) {
        case 0:
          setTechHovered([])
            break;
          case 1:
            setBeautyHovered([])
            break;
          case 2:
            setMebelHovered([])
            break;
          case 3:
            setOutletHovered([])
            break;
          default:
            break;
      }
    }
    const onMouseLeave = () => {
      switch (i) {
        case 0:
          setTechHovered([id])
            break;
          case 1:
            setBeautyHovered([id])
            break;
          case 2:
            setMebelHovered([id])
            break;
          case 3:
            setOutletHovered([id])
            break;
          default:
            break;
      }
    }
    
  return (
    <>
      <li
        onMouseOver={() =>onMouseOver()
          // i === 0 ? setTechHovered(id) : setBeautyHovered(id)
        }
        onMouseOut={() => (onMouseOut()
          // i === 0 ? setTechHovered([]) : setBeautyHovered([])
          )}
        className={
          // Number(i === 0 ? techHovered : beautyHovered) === Number(id)
          //   ? 'active'
          //   : ''
          Number(classActive()) === Number(id)
            ? 'active'
            : ''

        }
        onMouseLeave={() =>onMouseLeave()
          // i === 0 ? setTechHovered([id]) : setBeautyHovered([id])
        }
        value={id}
      >
        <Link
          href={{
            pathname: `/category/${children?.translations?.mn_MN?.slug}`,
          }}
        >
          <a onClick={(e) => handleClick(e, children)}>
            <p>{children?.translations?.mn_MN?.name}</p>
          </a>
        </Link>
        {/* <div className="drop-this">
          <ul className="list-unstyled">{childItems}</ul>
        </div> */}
      </li>
    </>
  )
}

const RootCategories = ({ categories }) => {
  const [techHovered, setTechHovered] = useState([0])
  const [beautyHovered, setBeautyHovered] = useState([1])
  const [mebelHovered, setMebelHovered] = useState([2])
  const [travelHovered, setTravelHovered] = useState([3])
  const [outletHovered, setOutletHovered] = useState([4])
  const { data: rootCategories, loading: rootLoading } = useGetCategoryRoot({
    subUrl: 'shop/taxons',
    initData: categories,
  })

  const router = useRouter()
  const { setValues } = useData()

  function toggleClick() {
    if (document.body.className === 'activate-drop') {
      document.body.classList.remove('activate-drop')
    } else {
      document.body.classList.add('activate-drop')
    }
  }

  const handleClick = (e, url) => {
    router.push({
      pathname: `/category/${url?.translations?.mn_MN?.slug}`,
    })
    setValues({ categoryName: url?.translations?.mn_MN?.name })
  }
  const handleTopClick = (e, url) => {
    setValues({ categoryName: url?.translations?.mn_MN?.name })
  }
  const placeholderImg = '/product-img-placeholder.svg'
  const rootItems =
    rootCategories &&
    rootCategories.map((categories, i) => {
      // const categoryImage = categories.images[0]?.path
      const imagePath = () =>{
        switch (i) {
          case 0:
            return "/static/images/category/tech.png";
          case 1:
            return "/static/images/category/beauty.png";
          case 2:
            return "/static/images/category/showroom.webp";
          case 3:
            return "/static/images/category/fur.png"  
          case 4:
            return "/static/images/category/travel.webp";
          case 5:
              return "/static/images/category/outlet.webp";
          default:
            return placeholderImg;
        }
      }
      const onMouseOver = () => {
        switch (i) {
          case 0:
            setTechHovered(i)
              break;
            case 1:
              setBeautyHovered(i)
              break;
            case 2:
              setMebelHovered(i)
              break;
            default:
              break;
        }
      }
      const onMouseLeave = () => {
        switch (i) {
          case 0:
            setTechHovered([i])
              break;
            case 1:
              setBeautyHovered([i])
              break;
            case 2:
              setMebelHovered([i])
              break;
            default:
              break;
        }
      }

      return (
        <Fragment key={i}>
          <div
          className='content left-content d-flex align-items-stretch'
            // className={`content ${
            //   i === 0
            //     ? 'left-content d-flex align-items-stretch'
            //     : 'right-content'
            // }`}
          >
            <ul className="list-unstyled">
              <li
                onMouseOver={() => onMouseOver()
                  // i === 0 ? setTechHovered(i) : setBeautyHovered(i)
                }
                onMouseLeave={() => onMouseLeave()
                  // i === 0 ? setTechHovered([i]) : setBeautyHovered([i])
                }
                className={
                  Number(techHovered) === i || Number(beautyHovered) === i || Number(mebelHovered) === i || Number(outletHovered) === i || Number(travelHovered) === i
                    ? 'active'
                    : ''
                }
              >
                <Link
                  href={`/category/${categories?.translations?.mn_MN?.slug}`}
                >
                  <a onClick={(e) => handleClick(e, categories)}>
                    <Image
                      // src={`/static/images/${i === 0 ? 'tech' : 'beauty'}.png`}
                      src={imagePath()}
                      alt="category logo" 
                      loading="lazy"
                      height={20} width={200} quality={75}
                    />
                  </a>
                </Link>
              </li>
              {categories?.children.map((value, key) => {
                return (
                  <Item
                    key={parseInt(`${i}${key}`)}
                    id={parseInt(`${i + 2}${key}`)}
                    techHovered={techHovered}
                    setTechHovered={setTechHovered}
                    beautyHovered={beautyHovered}
                    setBeautyHovered={setBeautyHovered}
                    mebelHovered={mebelHovered}
                    setMebelHovered={setMebelHovered}
                    outletHovered={outletHovered}
                    setOutletHovered={setOutletHovered}
                    travelHovered={travelHovered}
                    setTravelHovered={setTravelHovered}
                    i={i}
                    handleClick={handleClick}
                  >
                    {value}
                  </Item>
                )
              })}
            </ul>
          </div>
        </Fragment>
      )
    })

  return (
    <nav className="top-nav d-none d-lg-flex">
      <ul className="list-unstyled d-flex align-items-center text-center">
        <li className="nav-btn">
          <button
            type="button"
            onClick={toggleClick}
            className="dropdown-enabler d-flex align-items-center"
          >
            <span className="nav-icon">
              <span className="one-line" />
            </span>
            <span>Ангилал</span>
          </button>
          <div className="new-dropdown">
            <div className="dropdown-wrap d-flex justify-content-between">
              {!rootLoading && rootItems}
            </div>
          </div>
          <div
            className="blackout-drop dropdown-enabler"
            onClick={toggleClick}
          />
        </li>
        <li>
          <Link href={`/brands`}>
            <a>Брэндүүд</a>
          </Link>
        </li>
        <li>
          <Link href={`/collection/belgiin-bagtsuud?code=collection_022`}>
            <a>Бэлгийн багц</a>
          </Link>
        </li>
        <li>
          <Link href={`/credit`}>
            <a>Зээлийн заавар</a>
          </Link>
        </li>
      </ul>
    </nav>
  )
}
export default RootCategories
