import dynamic from 'next/dynamic'
import dayjs from 'dayjs'
import Header from 'components/global/header'
import Footer from 'components/global/footer'
import MobileMenu from 'components/global/mobileMenu'
import Loader from 'components/loading/loader'
import MobileUserProfile from 'layouts/MobileUserProfile'
import TopBar from 'components/global/topBar'
const GolomtPromotionModal = dynamic(
  () => import('components/global/golomtPromotionModal'),
  { ssr: false }
)
const exprDate = '2024-09-22'
const nowDate = dayjs().format('YYYY-MM-DD')
function BaseLayout(props) {
  const { children, loading, categories } = props
  return (
    <>
      {loading && <Loader />}
      <div className="wrap">
        <TopBar />
        <Header categories={categories} />
        {children}
      </div>
      <Footer />
      <MobileMenu categories={categories} />
      {exprDate > nowDate && <GolomtPromotionModal />}
      <MobileUserProfile />
    </>
  )
}

export default BaseLayout
