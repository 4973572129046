import Image from 'next/image'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { useAuthState } from 'contexts/auth'
import useMediaQuery from 'components/hooks/useMediaQuery'

const MobileUserProfile = ({ currentTab }) => {
  const router = useRouter()
  const query = router.route || []
  const {
    loading: isLoading,
    isAuthenticated: isLoggedIn,
    user: currentUser,
    setLogout,
  } = useAuthState()
  function logout() {
    setLogout()
    router.push('/')
    document.body.classList.remove('user-menu-active')
  }

  function toggleUserClick() {
    if (document.body.className === 'user-menu-active') {
      document.body.classList.remove('user-menu-active')
    } else {
      document.body.classList.add('user-menu-active')
    }
  }

  const isMobile = useMediaQuery('(max-width: 910px)');

  return (
    isMobile ? <section className="user-section d-block">
    <div className="user-menu white-bg p-4">
      <div className="user-id d-flex align-items-center">
        <Image
          src="/static/images/profileImage.svg"
          alt="Landscape picture"
          layout={'fixed'}
          width={64}
          height={64}
          loading='lazy'
          quality='75'
        />
        <div className="info">
          <p className="name">{currentUser?.firstName}</p>
          <p className="email">{currentUser?.phoneNumber}</p>
        </div>
      </div>
      <ul className="list-unstyled" >
        <li className={`${query === '/user/profile' ? 'active' : ''}`} onClick={toggleUserClick}>
          <Link href={`/user/profile`}>
            <a>
              <img
                src="/static/images/icons/white-user.svg"
                className="white" alt='user' loading='lazy'
              />
              <img
                src="/static/images/icons/user.svg"
                className="black" alt='user' loading='lazy'
              />
              <span>Профайл</span>
            </a>
          </Link>
        </li>
        {/* {currentUser?.directInvoice ? 
        <li className={`${query === '/user/invoice' ? 'active' : ''}`} onClick={toggleUserClick}>
          <Link href={`/user/invoice`}>
            <a>
              <img
                src="/static/images/icons/white-user.svg"
                className="white" alt='user' loading='lazy'
              />
              <img
                src="/static/images/icons/user.svg"
                className="black" alt='user' loading='lazy'
              />
              <span className="fw-bold">Нэхэмжлэл үүсгэх</span>
            </a>
          </Link>
        </li> 
           : 
        null
        } */}

        <li
          className={`${query === '/user/orders' || currentTab === 'order' ? 'active' : ''
            }`}
            onClick={toggleUserClick}
        >
          <Link href={`/user/orders`}>
            <a>
              <img
                src="/static/images/icons/white-cart-cart.svg"
                className="white" alt='user' loading='lazy'
              />
              <img
                src="/static/images/icons/cart-cart.svg"
                className="black" alt='user' loading='lazy'
              />
              <span>Миний захиалга</span>
            </a>
          </Link>
        </li>
        <li
          className={`${query === '/user/address' || currentTab === 'address'
            ? 'active'
            : ''
            }`}
            onClick={toggleUserClick}
        >
          <Link href={`/user/address`}>
            <a>
              <img
                src="/static/images/icons/white-cart-delivery.svg"
                className="white" alt='user' loading='lazy'
              />
              <img
                src="/static/images/icons/cart-delivery.svg"
                className="black" alt='user' loading='lazy'
              />
              <span>Хүргэлтийн хаягууд</span>
            </a>
          </Link>
        </li>
        <li className={`${query === '/user/wishlists' ? 'active' : ''}`} onClick={toggleUserClick}> 
          <Link href={`/user/wishlists`}>
            <a href="#">
              <img
                src="/static/images/icons/white-heart-small.svg"
                className="white" alt='user' loading='lazy'
              />
              <img
                src="/static/images/icons/heart-small.svg"
                className="black" alt='user' loading='lazy'
              />
              <span>Хадгалсан бараа</span>
            </a>
          </Link>
        </li>
        <li className="leave">
          <button type="button">
            <img
              src="/static/images/icons/times-white.svg"
              className="white" alt='user' loading='lazy'
            />
            <img
              src="/static/images/icons/times.svg"
              className="black" alt='user' loading='lazy'
            />
            <span onClick={logout}>Сайтаас гарах</span>
          </button>
        </li>
      </ul>
    </div>
  </section> : null
  )
}

export default MobileUserProfile
