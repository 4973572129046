import Link from 'next/link'
import { useState, useEffect } from 'react'
import { useCartState } from '@/contexts/cart'
import { useAuthState } from '@/contexts/auth'
import Cookies from 'js-cookie'
import { useRouter } from 'next/router'
import { useUI } from '@/contexts/uiContext'
import SearchBar from '@/components/global/searchBar'
import Image from 'next/image'
import dayjs from 'dayjs'
import useMediaQuery from 'components/hooks/useMediaQuery'

const TopSection = () => {
  const { count, wishCount } = useCartState()
  const router = useRouter()
  const query = router.route || []
  const {
    loading: isLoading,
    isAuthenticated: isLoggedIn,
    user: currentUser,
    setLogout,
  } = useAuthState()
  const [user, setUser] = useState({})
  const [snow] = useState([1,2,3,4,5,6,7,8,9,10,11,13,14,15,16,17,18,19,20])
  const { openUserProfile, closeUserProfile, toggleUserProfile } = useUI()
  const cookieUser = Cookies.get('user')
  const exprDate = "2023-01-01"
  const nowDate = dayjs().format("YYYY-MM-DD")
  const isMobile = useMediaQuery('(max-width: 992px)');

  useEffect(() => {
    if (cookieUser) {
      setUser(JSON.parse(cookieUser))
    }
  }, [])

  useEffect(() => {
    setTimeout(() => {
      Cookies.remove('user')
    }, 2000)
  }, [])

  function toggleClick() {
    if (
      document.body.className === 'activate-mobile-nav display-mobile-nav-list'
    ) {
      document.body.classList.remove('activate-mobile-nav')
      document.body.classList.remove('display-mobile-nav-list')
    } else {
      document.body.classList.add('activate-mobile-nav')
    }
  }

  function toggleUserClick() {
    if (document.body.className === 'user-menu-active') {
      document.body.classList.remove('user-menu-active')
    } else {
      document.body.classList.add('user-menu-active')
    }
  }
  return (
    <>
      <div className="top-section d-flex align-items-center justify-content-between">
        <button
          type="button"
          className="mobile-nav-btn mobile-nav-activator d-block d-lg-none col"
          onClick={toggleClick}
        >
          <span className="nav-icon" style={{ "marginLeft": '0.75rem' }}>
            <span className="one-line" />
          </span>
          <p className="d-none d-lg-block">Үндсэн цэс</p>
        </button>
        <Link href="/">
          <a className="logo">
            <Image
              src="/static/images/header_logo_v2.webp"
              alt="Banana logo"
              layout={'fixed'}
              width={120}
              height={60}
              loading="eager"
              quality={75}
            />
          </a>
        </Link>
        <SearchBar mobile={false} />
        {/* menu */}
        <ul className="d-none top-three-nav list-unstyled d-lg-flex align-items-center" >
          <li>
            <Link href={`/discount`}>
              <button className="btn-7">
                <span className='btn-7-text'>
                  Banana Sales
                </span>
                <img src='/static/images/header/top-section/sale-arrow.svg'/>
              </button>
            </Link>
          </li>
          <li>
            <Link href={`/category/banana-outlets`}>
              <button className="button-5">
                <span className='button-5-text'>
                  Banana Outlet
                </span>
                <img src="/static/images/header/top-section/outlet.svg" alt='outlet-img'/>
                </button>
            </Link>
          </li> 
        </ul>
        <ul className="top-action list-unstyled d-flex align-items-center text-center col justify-content-end">
          <li className="favourite d-none d-lg-block">
            <Link
              href={{
                pathname: isLoggedIn ? '/user/wishlists' : '/auth/login',
                query: !isLoggedIn && {
                  from: 'user/wishlists',
                },
              }}
            >
              <a>
                <img
                  src="/static/images/icons/heart.svg"
                  alt="favourite products"
                />
                <strong>{wishCount ? wishCount : 0}</strong>
                <p>Хадгалсан</p>
              </a>
            </Link>
          </li>
          <li className="cart">
            <Link href={`/cart`}>
              <a className={'link-this'}>
                <img src="/static/images/icons/cart.svg" alt="cart" />
                <strong>{count ? count : 0}</strong>
                <p className="d-none d-lg-block">Сагс</p>
              </a>
            </Link>
            {/* <div
              className='popover'
              id="popover"
              style={{}}
            >
              lol
            </div> */}
          </li>
          {isLoading && cookieUser ? (
            <li className="user logged">
              <Link href={`/user/profile`}>
                <a className="activate-user-menu link-this">
                  <img src="/static/images/profileImage.svg" alt="profile" />
                  <p className="d-none d-lg-block">{user.firstName}</p>
                </a>
              </Link>
            </li>
          ) : isLoggedIn ? (
            <>
              {isMobile ? (
                <li className="user">
                  <button
                    type="button"
                    className="activate-user-menu link-this"
                    onClick={toggleUserClick}
                  >
                    <img src="/static/images/profileImage.svg" alt="profile" />
                    <p className="d-none d-lg-block">
                      {currentUser?.firstName}
                    </p>
                  </button>
                </li>
              ): (
                <li className="user logged">
                <Link href={`/user/profile`}>
                  <a className="activate-user-menu link-this">
                    <img
                      src="/static/images/profileImage.svg"
                      alt="profile"
                    />
                    <p className="d-none d-lg-block">
                      {currentUser?.firstName}
                    </p>
                  </a>
                </Link>
              </li>
              )}
            </>
          ) : (
            !isLoggedIn && (
              <li className="user">
                {router.asPath.startsWith('/auth/login') ? (
                  <a href={'#'} className={'link-this'}>
                    <img src="/static/images/icons/user.svg" alt="user" />
                    <p className="d-none d-lg-block">Нэвтрэх</p>
                  </a>
                ) : router.asPath === '/' ? (
                  <Link
                    href={{
                      pathname: `/auth/login`,
                    }}
                  >
                    <a className={'link-this'}>
                      <img src="/static/images/icons/user.svg" alt="user" />
                      <p className="d-none d-lg-block">Нэвтрэх</p>
                    </a>
                  </Link>
                ) : (
                  <Link
                    href={{
                      pathname: `/auth/login`,
                      query: {
                        from: router.asPath.substring(1),
                      },
                    }}
                  >
                    <a className={'link-this'}>
                      <img src="/static/images/icons/user.svg" alt="user" />
                      <p className="d-none d-lg-block">Нэвтрэх</p>
                    </a>
                  </Link>
                )}
              </li>
            )
          )}
        </ul>
      </div>
      <div
        className="blackout-user-menu activate-user-menu"
        onClick={toggleUserClick}
      />
      {
         exprDate > nowDate && snow.map((i)=>{
          return <div key={i} className="snowflake">❅</div>
        })
      }
    </>
  )
}
export default TopSection
