import { Swiper, SwiperSlide } from 'swiper/react'
import dayjs from 'dayjs'
import { useState, useEffect } from 'react'

function TopBar() {
  const [show, setShow] = useState(false)

  useEffect(() => {
    const exitDate = localStorage.getItem('topAds')
    const currentDate = dayjs()
    if (currentDate.diff(exitDate, 'hours') > 24 || exitDate === null)
      setShow(true)
  }, [])

  function closeAds() {
    setShow(false)
    localStorage.setItem('topAds', dayjs())
  }

  return (
    <div className="top-bar d-none d-md-block d-lg-block">
      {show && (
        <div className="container">
          <div className="alert alert-dismissible fade show d-flex align-items-center">
            <Swiper
              className={'swiper top-bar-slide'}
              loop={true}
              autoplay={{
                delay: 3000,
                disableOnInteraction: false,
              }}
              navigation={{
                nextEl: '.top-bar-next',
                prevEl: '.top-bar-prev',
              }}
            >
              <div className="swiper-wrapper">
                <SwiperSlide key={`slide_1`}>
                  <p>300 брэндийн 10,000 бүтээгдэхүүний өргөн сонголт</p>
                </SwiperSlide>
                <SwiperSlide key={`slide_2`}>
                  <p>100,000₮-с дээш худалдан авалтад хүргэлт ҮНЭГҮЙ</p>
                </SwiperSlide>
              </div>
            </Swiper>
            <div className="d-flex align-items-center ps-3 me-5">
              <div className="top-bar-prev me-2">
                <img src="/static/images/icons/chevron.svg" alt='category-icon' loading='lazy'/>
              </div>
              <div className="top-bar-next">
                <img src="/static/images/icons/chevron.svg" alt='category-icon' loading='lazy'/>
              </div>
            </div>
            <button
              type="button"
              className="btn-close"
              onClick={closeAds}
              style={{ opacity: 1 }}
            >
              <img src="/static/images/icons/times.svg" alt='times'/>
            </button>
          </div>
        </div>
      )}
    </div>
  )
}

export default TopBar
