import Head from 'next/head'
import { useRouter } from 'next/router'
import { siteSettings } from 'components/settings'

function BasePage(props) {
  const router = useRouter()
  const {
    title = siteSettings.name,
    metaDescription = siteSettings.description,
    canonicalPath,
    children,
  } = props

  return (
    <>
      <Head>
        <title>{title}</title>
        <meta name="theme-color" content="#ffffff" />
        <meta name="viewport" content="initial-scale=1.0, width=device-width" />
        <meta name="description" key="description" content={metaDescription} />
        <meta name="title" key="title" content={title} />
        <meta name="category" content="shopping,ecommerce" />
        <meta name="subject" content="Ecommerce" />
        <meta
          name="keywords"
          content="bananamall, banana, ecommerce, shopping, бананашоп, банана, дэлгүүр, lend, lendmn, superup, superup.mn, чиний гарт чиний дэлгүүр, гоо сайхан, технологи, goo saikhan, technology, Гар утас, Used утас, Used phone, Openbox phone, Openbox утас, Хэрэглэсэн утас, Цахилгаан бараа, Tsahilgaan baraa, Гоо сайхны бараа, Goo saihnii baraa, Digital zeel, loan, дижитал зээл"
        />
        <meta
          name="description"
          content="Гар утас, Used утас, Used phone, Openbox phone, Openbox утас, Хэрэглэсэн утас, Цахилгаан бараа, Tsahilgaan baraa, Tsakhilgaan baraa, Гоо сайхны бараа, Goo saihnii baraa, Goo saikhnii baraa Digital zeel, loan, дижитал зээл, Голомт банкны дижитал хэрэглээний зээлийн үйлчилгээ, Bananamall-с хүссэн бүтээгдэхүүн, үйлчилгээг Голомт банкны зээлийн үйлчилгээг ашиглан 1.7%-н хүүтэй, 1%-н шимтгэлтэй 10 хүртэлх сая төгрөгийн зээлийг 30 сар хуваан төлөх нөхцлөөр 1-10 минутад зээлийн тооцооллоо онлайнаар хүлээн авч худалдан авалт хийх боломжтой үйлчилгээ юм."
        />
        <meta property="og:title" key="og:title" content={title} />
        <meta property="og:locale" key="og:locale" content="mn_MN" />
        {router.asPath === '/' && (
          <meta property="og:image" content="/fb_cover.png" />
        )}
        <meta property="fb:app_id" content="798968490635642" />
        <meta property="fb:page_id" content="1421291734678399" />
        <meta property="og:email" content="contact@bananamall.mn" />
        <meta property="og:phone_number" content="77774080" />
        <meta
          property="og:street-address"
          content="Улаанбаатар, Сүхбаатар дүүрэг, 1-р хороо, Moncon office, 5 давхарт"
        />
        <meta property="og:locality" content="Ulaanbaatar" />
        <meta property="og:region" content="UB" />
        <meta property="og:country-name" content="Mongolia" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="https://www.bananamall.mn" />
        <meta name="twitter:title" content="Banana Mall" />
        <meta name="twitter:image" content="/fb_cover.png" />
        <meta
          property="og:url"
          key="og:url"
          content={`${siteSettings.author.websiteUrl}${router.asPath}`}
        />
        <meta property="og:type" key="og:type" content="website" />
        <meta
          property="og:description"
          key="og:description"
          content={metaDescription}
        />
        <link
          href="https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap"
          rel="stylesheet"
        />

        <link
          rel="canonical"
          href={`${siteSettings.author.websiteUrl}${canonicalPath ? canonicalPath : router.asPath}`}
        />
      </Head>
      {children}
    </>
  )
}

export default BasePage
