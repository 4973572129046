import TopSection from 'components/global/topSection'
import RootCategories from 'components/global/rootCategories'
import useScrollDirection from 'components/hooks/useScrollDirection'

function Header({ categories }) {
  const scrollDirection = useScrollDirection()

  return (
    <header
      className={`top-container ${scrollDirection === 'up' ? '' : 'hide'}`}
    >
      <div className="container">
        {/*Лого, хайлт, линк, сагс*/}
        <TopSection />
        {/*Лого, хайлт, линк, сагс*/}
        {/*Үндсэн ангилал*/}
        <RootCategories categories={categories} />
        {/*Үндсэн ангилал*/}
      </div>
    </header>
  )
}
export default Header
