import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'

const HeadFeaturedCategroyLoading = () => {
  return (
    <>
      {Array(10)
        .fill()
        .map((item, i) => (
          <li key={i}>
            <a className="lv-1">
              <Skeleton height={20} width={60} />
            </a>
          </li>
        ))}
    </>
  )
}
export default HeadFeaturedCategroyLoading
