import { useGetCategoryRoot } from '@/actions/categories'
import { Fragment, useState } from 'react'
import Link from 'next/link'
import { useAuthState } from '@/contexts/auth'
import { useRouter } from 'next/router'
import Image from 'next/image'

const ThreeItem = ({ children }) => {
  return (
    <li>
      <Link href={`/category/${children?.translations?.mn_MN?.slug}`}>
        <a>- {children?.translations?.mn_MN?.name}</a>
      </Link>
    </li>
  )
}

const ChildItem = ({ children }) => {
  return (
    <li className={'active'}>
      <Link href={`/category/${children?.slug}`}>
        <a>{children?.translations?.mn_MN?.name}</a>
      </Link>
    </li>
  )
}

const Item = ({ children, id }) => {
  const [selected, setSelected] = useState(false)
  const childItem = children?.children.length > 0
  const childItems = childItem ? (
    children &&
    children?.children.map((value, key) => {
      return <ChildItem key={key}>{value}</ChildItem>
    })
  ) : (
    <ChildItem key={id}>{children}</ChildItem>
  )
  function toggleClick() {
    setSelected(!selected)
  }
  return (
    <li
      className={children.id === id && selected ? 'active' : ''}
      style={{ marginLeft: '1rem ' }}
    >
      <button
        type="button"
        onClick={() => {
          toggleClick()
        }}
      >
        <span>{children?.translations?.mn_MN?.name}</span>
        <img
          src="/static/images/icons/chevron.svg"
          alt="icon chevron"
          className="chevron"
          loading="lazy"
        />
      </button>
      <ul className="list-unstyled">{childItems}</ul>
    </li>
  )
}

const MobileMenu = ({ categories }) => {
  const { data: rootCategories, loading: rootLoading } = useGetCategoryRoot({
    subUrl: 'shop/taxons',
    initData: categories,
  })
  const { isAuthenticated: isLoggedIn } = useAuthState()
  const [selected, setSelected] = useState(false)
  const router = useRouter()
  function toggleClick() {
    if (
      document.body.className === 'activate-mobile-nav display-mobile-nav-list'
    ) {
      document.body.classList.remove('activate-mobile-nav')
      document.body.classList.remove('display-mobile-nav-list')
    }
    if (document.body.className === 'activate-mobile-nav') {
      document.body.classList.remove('activate-mobile-nav')
    }
  }

  function toggleCategories() {
    setSelected(!selected)
    if (
      document.body.className === 'activate-mobile-nav display-mobile-nav-list'
    ) {
      document.body.classList.remove('display-mobile-nav-list')
    } else {
      document.body.classList.add('display-mobile-nav-list')
    }
  }

  const rootItems =
    rootCategories &&
    rootCategories.map((categories, i) => {
      const imagePath = () => {
        switch (i) {
          case 0:
            return '/static/images/category/tech.png'
          case 1:
            return '/static/images/category/beauty.png'
          case 2:
            return '/static/images/category/showroom.webp'
          case 3:
            return '/static/images/category/fur.png'
          case 4:
            return '/static/images/category/travel.webp'
          case 5:
            return '/static/images/category/outlet.webp'
          default:
            ''
        }
      }
      return (
        <Fragment key={i}>
          <li className="title" key={i}>
            <a href={`/category/${categories?.slug}`}>
              <Image
                src={imagePath() || '/'}
                alt="categories"
                height={20}
                loading="lazy"
                width={150}
                quality={75}
              />
            </a>
          </li>
          {categories?.children.map((value, key) => {
            return (
              <Item key={parseInt(`${i}${key}`)} id={value.id}>
                {value}
              </Item>
            )
          })}
        </Fragment>
      )
    })

  return (
    <>
      <div className="mobile-nav-container">
        <h2 className="d-flex align-items-center justify-content-between">
          <span>Үндсэн цэс</span>
          <button
            type="button"
            aria-label="menu button"
            className="btn btn-close mobile-nav-activator"
            onClick={toggleClick}
          >
            <img
              src="/static/images/icons/times.svg"
              alt="icon chevron"
              className="chevron"
              loading="lazy"
            />
          </button>
        </h2>
        <ul className="link-list list-unstyled">
          <li>
            <Link href={'/discount'}>
              <a
                href="#"
                className="d-flex align-items-center justify-content-between"
                onClick={toggleClick}
              >
                <div className="d-flex align-items-center">
                  <img
                    src="/static/images/icons/discount.webp"
                    className="flex-shrink-0"
                    loading="lazy"
                    alt="sale"
                  />
                  <p style={{ color: 'red' }}>Хямдрал цэс</p>
                </div>
              </a>
            </Link>
          </li>
          <li>
            <Link href={'/category/banana-outlets'}>
              <a
                href="#"
                className="d-flex align-items-center justify-content-between"
                onClick={toggleClick}
              >
                <div className="d-flex align-items-center">
                  <img
                    src="/static/images/icons/mobile-phone.webp"
                    className="flex-shrink-0"
                    loading="lazy"
                    alt="heart"
                  />
                  <p>Outlets</p>
                </div>
              </a>
            </Link>
          </li>
          <li>
            <Link href={'https://showroom.bananamall.mn'}>
              <a
                href="https://showroom.bananamall.mn"
                className="d-flex align-items-center justify-content-between"
                onClick={toggleClick}
              >
                <div className="d-flex align-items-center">
                  <img
                    src="/static/images/icons/running.webp"
                    className="flex-shrink-0"
                    loading="lazy"
                    alt="brands"
                  />
                  <p>Спорт цэс</p>
                </div>
              </a>
            </Link>
          </li>
          <li>
            <Link href={'/category/ayalal'}>
              <a
                href="#"
                className="d-flex align-items-center justify-content-between"
                onClick={toggleClick}
              >
                <div className="d-flex align-items-center">
                  <img
                    src="/static/images/icons/tent.webp"
                    className="flex-shrink-0"
                    loading="lazy"
                    alt="collection"
                  />
                  <p>Аялал цэс</p>
                </div>
              </a>
            </Link>
          </li>
          <li>
            <Link href={'/collection/belgiin-bagtsuud?code=collection_022'}>
              <a
                href="#"
                className="d-flex align-items-center justify-content-between"
                onClick={toggleClick}
              >
                <div className="d-flex align-items-center">
                  <img
                    src="/static/images/icons/present.webp"
                    className="flex-shrink-0"
                    loading="lazy"
                    alt="collection"
                  />
                  <p>Бэлгийн багцууд</p>
                </div>
              </a>
            </Link>
          </li>

          <li>
            <Link href={'/credit'}>
              <a
                href="#"
                className="d-flex align-items-center justify-content-between"
                onClick={toggleClick}
              >
                <div className="d-flex align-items-center">
                  <Image
                    src="/static/images/icons/loan.webp"
                    className="flex-shrink-0"
                    loading="lazy"
                    alt="loan"
                    width={20}
                    height={20}
                    quality={75}
                  />
                  <p>Зээлийн заавар</p>
                </div>
              </a>
            </Link>
          </li>
          <li className={selected ? 'active' : ''}>
            <a
              href="#"
              className="d-flex align-items-center justify-content-between"
              onClick={() => toggleCategories()}
            >
              <div className="d-flex align-items-center">
                <img
                  src="/static/images/icons/categories.svg"
                  className="flex-shrink-0"
                  loading="lazy"
                  alt="categories"
                />
                <p>Ангиллууд</p>
              </div>
            </a>
          </li>
        </ul>
        <ul className="nav-list list-unstyled">{!rootLoading && rootItems}</ul>
        <ul className="contact-list list-unstyled d-flex align-items-center flex-wrap">
          <li className="phone">
            <a href="tel:77774080">
              <img
                src="/static/images/icons/yellow-solid-phone.svg"
                alt="icon chevron"
                className="chevron"
                loading="lazy"
              />
              <span>7777 4080</span>
            </a>
          </li>
          {['facebook', 'instagram', 'youtube'].map((item, index) => {
            return (
              <li className="social" key={`social_${index}`}>
                <Link
                  href={
                    index === 0
                      ? 'https://www.facebook.com/bananamall.mn/'
                      : index === 1
                      ? 'https://www.instagram.com/bananamall.mn/'
                      : 'https://www.youtube.com/channel/UC95uB2vH9KgAyOjS07KzYGQ'
                  }
                >
                  <a target="_blank" rel="noopener">
                    <img
                      src={`/static/images/icons/${item}.svg`}
                      alt="icon chevron"
                      className="chevron"
                      loading="lazy"
                    />
                  </a>
                </Link>
              </li>
            )
          })}
        </ul>
        <p>© 2022 Дижитал Молл ХХК</p>
      </div>
      <div
        className="blackout-mobile mobile-nav-activator"
        onClick={toggleClick}
      />
    </>
  )
}
export default MobileMenu
